

.container {
      font-family: 'Raleway', sans-serif;
    --subFont: 'Montserrat', sans-serif;
    --primaryColor: #f95959;
    --secondaryColor: #f7edd5;
    --inputColor: #bbbbbb;
    font-family: var(--subFont);
    background-color: var(--secondaryColor);
  max-width: 900px;
}

a {
  display: inline-block;
  text-decoration: none;
}

input {
  outline: none !important;
}

h1 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-weight: 700;
}

section#formHolder {
  padding: 50px 0;
}


.brand {
  padding: 20px;
  background: url(https://goo.gl/A0ynht);
  background-size: cover;
  background-position: center center;
  color: #fff;
  min-height: 540px;
  position: relative;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.6s cubic-bezier(1.000, -0.375, 0.285, 0.995);
  z-index: 9999;
}

.brand.active {
  width: 100%;
}

.brand::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: -1;
}

.brand a.logo {
  color: var(--primaryColor);
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  line-height: 1em;
}

.brand a.logo span {
  font-size: 30px;
  color: #fff;
  transform: translateX(-5px);
  display: inline-block;
}

.brand .heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: all 0.6s;
}

.brand .heading.active {
  top: 100px;
  left: 100px;
  transform: translate(0);
}

.brand .heading h2 {
  font-size: 70px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}

.brand .heading p {
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: 4px;
  font-family: var(--mainFont);
}

.brand .success-msg {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 60px;
}

.brand .success-msg p {
  font-size: 25px;
  font-weight: 400;
  font-family: var(--mainFont);
}

.brand .success-msg a {
  font-size: 12px;
  text-transform: uppercase;
  padding: 8px 30px;
  background: var(--primaryColor);
  text-decoration: none;
  color: #fff;
  border-radius: 30px;
}

.brand .success-msg p,
.brand .success-msg a {
  transition: all 0.9s;
  transform: translateY(20px);
  opacity: 0;
}

.brand .success-msg p.active,
.brand .success-msg a.active {
  transform: translateY(0);
  opacity: 1;
}


.form {
  position: relative;
}

.form .form-peice {
  background: #fff;
  min-height: 480px;
  margin-top: 30px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  color: var(--inputColor);
  padding: 30px 0 60px;
  transition: all 0.9s cubic-bezier(1.000, -0.375, 0.285, 0.995);
  position: absolute;
  top: 0;
  left: -30%;
  width: 130%;
  overflow: hidden;
}

.form .form-peice.switched {
  transform: translateX(-100%);
  width: 100%;
  left: 0;
}

form {
  padding: 0 40px;
  margin: 0;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
}

form .form-group {
  margin-bottom: 5px;
  position: relative;
}

form .form-group.hasError input {
  border-color: var(--primaryColor) !important;
}

form input::placeholder {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--subFont);
  transform: translateY(40px);
  transition: all 0.4s;
  cursor: text;
  z-index: -1;
}

form input::placeholder.active {
  transform: translateY(10px);
  font-size: 10px;
}

form input::placeholder.fontSwitch {
  font-family: var(--mainFont) !important;
  font-weight: 600;
}

form input:not([type=submit]) {
  background: none;
  outline: none;
  border: none;
  display: block;
  padding: 10px 0;
  width: 100%;
  border-bottom: 1px solid #eee;
  color: #444;
  font-size: 15px;
  font-family: var(--subFont);
  z-index: 1;
}

form input.hasError {
  border-color: var(--primaryColor);
}

form span.error {
  color: var(--primaryColor);
  font-family: var(--subFont);
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  right: 0;
  display: none;
}

form input[type=password] {
  color: var(--primaryColor);
}

form .CTA {
  margin-top: 30px;
}

form .CTA input {
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 30px;
  background: var(--primaryColor);
  color: #fff;
  border-radius: 30px;
  margin-right: 20px;
  border: none;
  font-family: var(--subFont);
}

form .CTA a.switch {
  font-size: 13px;
  font-weight: 400;
  font-family: var(--subFont);
  color: var(--inputColor);
  text-decoration: underline;
  transition: all 0.3s;
}

form .CTA a.switch:hover {
  color: var(--primaryColor);
}

footer {
  text-align: center;
}

footer p {
  color: #777;
}

footer a,
footer a:focus {
  color: #b8b09f;
  transition: all .3s;
  text-decoration: none !important;
}

footer a:hover {
  color: var(--primaryColor);
}


@media (max-width: 1200px) {
  .brand .heading h2 {
    font-size: 50px;
  }

  .brand .heading p {
    font-size: 12px;
  }

  .brand .success-msg p {
    font-size: 16px;
  }

  .brand .success-msg a {
    padding: 5px 30px;
    font-size: 10px;
  }
}

@media (max-width: 992px) {
  section#formHolder {
    padding: 0;
  }

  div.brand {
    min-height: 200px !important;
  }

  div.brand.active {
    min-height: 100vh !important;
  }

  div.brand .heading.active {
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  div.brand .success-msg p {
    font-size: 16px;
  }

  div.brand .success-msg a {
    padding: 5px 30px;
    font-size: 10px;
  }

  div.form {
    width: 80vw;
    min-height: 500px;
    margin-left: 10vw;
  }

  div.form .form-peice {
    margin: 0;
    top: 0;
    left: 0;
    width: 100% !important;
    transition: all .5s ease-in-out;
  }

  div.form .form-peice.switched {
    transform: translateY(-100%);
    width: 100%;
    left: 0;
  }

  div.form > form {
    width: 100% !important;
    padding: 60px;
    left: 50%;
  }
}

@media (max-width: 768px) {
  section#formHolder div.form {
    width: 100vw;
    margin-left: 0;
  }

  h2 {
    font-size: 50px !important;
  }
}

@media (max-width: 576px) {
  form {
    width: 90%;
  }

  form input {
    font-size: 14px;
  }

  form .CTA input {
    font-size: 10px;
    padding: 5px 15px;
  }

  footer p {
    font-size: 12px;
  }
}
