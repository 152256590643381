
@font-face {
  font-family: EV_Head_bd;
  src: url('/src/fonts/EngelVoelkersHead_W_Bd.woff');
}
@font-face {
  font-family: 'EV_head_rg';
  src: url('/src/fonts/EngelVoelkersHead_W_Rg.woff');
}@font-face {
  font-family: 'EV_Text_bd';
  src: url('/src/fonts/EngelVoelkersText_W_Bd.woff');
}@font-face {
  font-family: 'EV_Text_lt';
  src: url('/src/fonts/EngelVoelkersText_W_Lt.woff');
}@font-face {
  font-family: 'EV_Text_rg';
  src: url('/src/fonts/EngelVoelkersText_W_Rg.woff');
}
